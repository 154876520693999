<template>
  <div>
    <v-container fluid class="px-6 py-6">
      <v-row class="mt-8">
        <v-col cols="12">
          <div>
            <v-card class="card-shadow border-radius-xl">
              <div class="card-header-padding">
                <div class="d-flex align-center">
                  <div>
                    <h5 class="font-weight-bold text-h5 text-typo mb-0">
                      Blend Recipe
                    </h5>
                    <!-- <p class="text-sm text-body mb-0">
                      <v-icon color="#adb5bd" size=".875rem"
                        >fa-solid fa-mug-saucer</v-icon
                      >
                    </p> -->
                  </div>

                  <v-dialog v-model="dialog" max-width="850px" persistent>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn v-bind="attrs" v-on="on" elevation="0" dark :ripple="false" height="43" class="
                              font-weight-bold
                              text-capitalize
                              ms-auto
                              btn-primary
                              bg-success
                              py-3
                              px-6
                              ms-3
                            ">New Recipe</v-btn>
                    </template>
                    <v-card class="card-shadow border-radius-xl">
                      <div class="card-header-padding card-border-bottom">
                        <span class="font-weight-bold text-h5 text-typo mb-0">{{
                          formTitle
                        }}</span>
                      </div>

                      <v-card-text class="card-padding">
                        <v-container class="px-0">
                          <v-form ref="frm">
                            <v-row>
                              <v-col cols="12" md="4">
                                <label class="
                                        text-md text-typo
                                        font-weight-bolder
                                        ms-1
                                      ">Valid from</label>
                                <v-menu ref="mnu_frm_date" v-model="mnu_frm_date" :close-on-content-click="false"
                                  :return-value.sync="mnu_frm_date" transition="scale-transition" offset-y
                                  min-width="auto">
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-text-field v-model="editedItem.validFrom" readonly v-bind="attrs" v-on="on"
                                      hide-details="auto" class="
                                            input-style
                                            font-size-input
                                            text-light-input
                                            placeholder-light
                                            input-icon
                                            mt-5
                                          " dense flat filled solo height="43" placeholder="Date"
                                      persistent-hint></v-text-field>
                                  </template>
                                  <v-date-picker color="green lighten-1" header-color="primary"
                                    v-model="editedItem.validFrom" no-title scrollable>
                                    <v-spacer></v-spacer>
                                    <v-btn text color="primary" @click="mnu_frm_date = false">
                                      Cancel
                                    </v-btn>
                                    <v-btn text color="primary" @click="
                                      $refs.mnu_frm_date.save(
                                        editedItem.validFrom
                                      )
                                    ">
                                      OK
                                    </v-btn>
                                  </v-date-picker>
                                </v-menu>
                              </v-col>
                              <v-col cols="12" md="4">
                                <label class="
                                        text-md text-typo
                                        font-weight-bolder
                                        ms-1
                                      ">Valid to</label>
                                <v-menu ref="mnu_to_date" v-model="mnu_to_date" :close-on-content-click="false"
                                  :return-value.sync="mnu_to_date" transition="scale-transition" offset-y
                                  min-width="auto">
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-text-field v-model="editedItem.validTo" readonly @click="to_validation = ''"
                                      :error-messages="to_validation" v-bind="attrs" v-on="on" hide-details="auto" class="
                                            input-style
                                            font-size-input
                                            text-light-input
                                            placeholder-light
                                            input-icon
                                            mt-5
                                          " dense flat filled solo height="43" placeholder="Date"
                                      persistent-hint></v-text-field>
                                  </template>
                                  <v-date-picker color="green lighten-1" header-color="primary"
                                    v-model="editedItem.validTo" no-title scrollable :min="editedItem.validFrom">
                                    <v-spacer></v-spacer>
                                    <v-btn text color="primary" @click="mnu_to_date = false">
                                      Cancel
                                    </v-btn>
                                    <v-btn text color="primary" @click="
                                      $refs.mnu_to_date.save(
                                        editedItem.validTo
                                      )
                                    ">
                                      OK
                                    </v-btn>
                                  </v-date-picker>
                                </v-menu>
                              </v-col>
                              <v-col cols="12" md="4">
                                <label class="
                                        text-md text-typo
                                        font-weight-bolder
                                        ms-1
                                      ">Blend code</label>
                                <v-autocomplete v-model="editedItem.blendCodeId" :items="blendCode" item-text="name"
                                  item-value="id" color="rgba(0,0,0,.6)" class="
                                        input-style
                                        font-size-input
                                        text-light-input
                                        placeholder-light
                                        border-radius-md
                                        select-style
                                        mt-5
                                        mb-0
                                      " placeholder="Select a Blend code" :rules="[
                                        (v) => !!v || 'Blend code is Required',
                                      ]" outlined single-line height="48">
                                </v-autocomplete>
                              </v-col>
                            </v-row>
                            <v-row justify="center" v-for="(row, i) in editedItem.blendRecipeDetails
                              .length" v-bind:key="i">
                              <v-col cols="4">
                                <label class="
                                        text-md text-typo
                                        font-weight-bolder
                                        ms-1
                                      ">Item Name</label>
                                <v-text-field v-model="
                                  editedItem.blendRecipeDetails[i].itemName
                                " hide-details="auto" @keyup="uppercase(i)" class="
                                        input-style
                                        font-size-input
                                        text-light-input
                                        placeholder-light
                                        input-icon
                                        text-capitalize
                                      " dense flat filled solo placeholder="Item" :rules="[
                                        (v) => !!v || 'Item name is required',
                                      ]"></v-text-field>
                              </v-col>
                              <v-col cols="4">
                                <label class="
                                        text-md text-typo
                                        font-weight-bolder
                                        ms-1
                                      ">Percentage</label>
                                <v-text-field v-model.number="
                                  editedItem.blendRecipeDetails[i].percentage
                                " type="number" min="1" max="100" hide-details="auto" class="
                                        input-style
                                        font-size-input
                                        text-light-input
                                        placeholder-light
                                        input-icon
                                      " @change="validatePercentageSum(i)" dense flat filled solo
                                  placeholder="Percentage" :rules="[
                                    (v) =>
                                      !!v || 'Valid percentage is required',
                                  ]"></v-text-field>
                              </v-col>
                              <v-col cols="2" class="pa-1" align-self="center">
                                <span class="pa-2" align-self="center">
                                  <v-btn class="mx-2 mt-3 input-style" outlined small color="red" @click="remove(i)"
                                    v-show="
                                      i ||
                                      (!i &&
                                        editedItem.blendRecipeDetails.length >
                                        1)
                                    ">Remove
                                  </v-btn>
                                </span>
                              </v-col>
                              <v-col cols="2" class="pa-1" align-self="center">
                                <span class="pa-2" align-self="center">
                                  <v-btn class="mx-2 mt-3" outlined small color="green" @click="add()" v-show="
                                    i ==
                                    editedItem.blendRecipeDetails.length - 1
                                  ">Add
                                  </v-btn>
                                </span>
                              </v-col>
                            </v-row>
                          </v-form>
                        </v-container>
                      </v-card-text>

                      <v-card-actions class="card-padding d-flex justify-end">
                        <v-btn @click="close" elevation="0" :ripple="false" height="43" class="
                                font-weight-bold
                                text-capitalize
                                btn-ls btn-secondary
                                bg-light
                                py-3
                                px-6
                              ">Close</v-btn>

                        <v-btn @click="save" elevation="0" :ripple="false" height="43" dark class="
                                text-capitalize
                                btn-ls btn-primary
                                bg-success
                                py-3
                                px-6
                              ">Save</v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </div>
              </div>

              <v-card-text class="px-0 py-0">
                <v-data-table :headers="headers" :items="items" :search="search" class="table" :page.sync="page"
                  hide-default-footer @page-count="pageCount = $event" :items-per-page="itemsPerPage"
                  mobile-breakpoint="0">
                  <template v-slot:top>
                    <v-toolbar flat height="80">
                      <v-row>
                        <v-col cols="12" md="5">
                          <v-text-field hide-details class="
                                  input-style
                                  font-size-input
                                  text-light-input
                                  placeholder-light
                                  input-icon
                                " dense flat filled solo height="43" v-model="search" placeholder="Search">
                            <template slot="prepend-inner">
                              <v-icon color="#adb5bd" size=".875rem">fas fa-search</v-icon>
                            </template>
                          </v-text-field>
                        </v-col>
                      </v-row>

                      <v-spacer></v-spacer>

                      <v-dialog v-model="dialogDelete" max-width="500px">
                        <v-card class="card-shadow card-padding border-radius-xl">
                          <v-card-title class="pt-0 text-h5 text-typo justify-center">Are you sure you want to delete this
                            item?</v-card-title>
                          <v-card-actions class="pb-0">
                            <v-spacer></v-spacer>
                            <v-btn @click="closeDelete" elevation="0" :ripple="false" height="43" class="
                                    font-weight-bold
                                    text-capitalize
                                    btn-ls
                                    bg-light
                                    py-3
                                    px-6
                                  ">Cancel</v-btn>

                            <v-btn @click="deleteItemConfirm" elevation="0" :ripple="false" height="43" class="
                                    font-weight-bold
                                    text-capitalize
                                    btn-ls btn-primary
                                    bg-success
                                    py-3
                                    px-6
                                  ">Ok</v-btn>
                            <v-spacer></v-spacer>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                    </v-toolbar>
                  </template>
                  <!-- [`item.actions`]="{ item }" -->
                  <template v-slot:[`item.actions`]="{ item }">
                    <!-- <span> Example test {{ item.district }}</span> -->
                    <v-btn @click="editItem(item)" icon elevation="0" :ripple="false" height="28" min-width="36"
                      width="36" class="" color="green">Edit
                    </v-btn>

                    <v-btn @click="deleteItem(item)" icon elevation="0" :ripple="false" height="28" min-width="36"
                      width="36" class="ml-5" color="#FF0000">
                      delete
                    </v-btn>
                  </template>
                  <template v-slot:[`item.validTo`]="{ item }">
                    <span>
                      {{ formatDate(item.validTo) }}
                    </span>
                  </template>
                  <template v-slot:[`item.validFrom`]="{ item }">
                    <span>
                      {{ formatDate(item.validFrom) }}
                    </span>
                  </template>
                  <template v-slot:[`item.active`]="{ item }">
                    <span v-if="item.active"> Active </span>
                    <span v-else> Inactive </span>
                  </template>
                </v-data-table>
              </v-card-text>
              <v-card-actions class="card-padding">
                <v-row>
                  <v-col cols="6" lg="3" class="d-flex align-center">
                    <span class="text-body me-3 text-sm">Items per page:</span>
                    <v-text-field hide-details type="number" outlined min="-1" max="15"
                      background-color="rgba(255,255,255,.9)" color="rgba(0,0,0,.6)" light :value="itemsPerPage"
                      @input="itemsPerPage = parseInt($event, 10)" placeholder="Items per page" class="
                            font-size-input
                            placeholder-lighter
                            text-color-light
                            input-alternative input-focused-alternative input-icon
                          ">
                    </v-text-field>
                  </v-col>
                  <v-col cols="6" class="ml-auto d-flex justify-end">
                    <v-pagination prev-icon="fa fa-angle-left" next-icon="fa fa-angle-right" class="pagination"
                      color="#38bd34" v-model="page" :length="pageCount" circle></v-pagination>
                  </v-col>
                </v-row>
              </v-card-actions>
            </v-card>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import api from "../api";

export default {
  name: "paginated-tables",
  data() {
    return {
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      dialog: false,
      dialogDelete: false,
      mnu_frm_date: false,
      mnu_to_date: false,
      blendCode: [],
      items: [],
      tempItems: [],
      search: "",
      to_validation: "",
      editedIndex: -1,
      editedItem: {
        validFrom: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
        validTo: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
        id: 0,
        blendCodeId: 0,
        blendRecipeDetails: [{ itemName: "", percentage: "" }],
      },
      defaultItem: {
        validFrom: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
        validTo: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
        id: 0,
        blendCodeId: 0,
        blendRecipeDetails: [{ itemName: "", percentage: "" }],
      },
      headers: [
        {
          text: "Recipe",
          width: 250,
          sortable: true,
          value: "name",
        },
        {
          text: "Valid From",
          width: 250,
          sortable: true,
          value: "validFrom",
        },
        {
          text: "Valid To",
          width: 250,
          sortable: true,
          value: "validTo",
        },
        {
          text: "Blend Code",
          width: 250,
          sortable: true,
          value: "blendCodeName",
        },
        {
          text: "Blend Type",
          width: 250,
          sortable: true,
          value: "blendTypeName",
        },
        {
          text: "Active status",
          width: 250,
          sortable: true,
          value: "active",
        },

        { text: "Actions", value: "actions", sortable: false },
      ],
    };
  },

  created() {
    this.initialize();
  },
  methods: {
    validate() {
      if (this.editedItem.validFrom > this.editedItem.validTo) {
        this.to_validation = "To date should be greater than From date";
        return false;
      } else {
        return this.$refs.frm.validate();
      }
    },
    reset() {
      return this.$refs.frm.reset();
    },

    uppercase(i) {
      this.editedItem.blendRecipeDetails[i].itemName =
        this.editedItem.blendRecipeDetails[i].itemName.toUpperCase();
    },

    showSuccessAlert(message) {
      this.$swal({
        title: "Success",
        text: message,
        type: "success",
        timer: 1000,
        icon: "success",
        showConfirmButton: false,
      });
    },

    showErrorAlert(message) {
      this.$swal({
        title: "Error",
        text: message,
        type: "error",
        timer: 2500,
        icon: "error",
        showConfirmButton: false,
      });
    },
    showWarningAlert(message) {
      this.$swal({
        title: "Wrong input",
        text: message,
        showConfirmButton: false,
        type: "warning",
        timer: 3000,
        icon: "warning",
        showConfirmButton: false,
      });
    },
    async initialize() {
      this.tempItems = [];
      this.items = [];
      let res = await api.get();
      this.items = res.data;

      this.tempItems = structuredClone(this.items);

      let blend_code = await api.getBlendCode();
      this.blendCode = blend_code.data.filter((item) => {
        if (item.active) {
          return item;
        }
      });
    },
    formatDate(date) {
      var d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();
      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;
      return [year, month, day].join("-");
    },

    add() {
      this.editedItem.blendRecipeDetails.push({
        itemName: "",
        percentage: "",
      });
    },

    remove(index) {
      this.editedItem.blendRecipeDetails.splice(index, 1);
    },
    editItem(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);

      this.editedItem.validFrom = this.formatDate(item.validFrom);
      this.editedItem.validTo = this.formatDate(item.validTo);
      this.dialog = true;
    },

    async deleteItem(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    async validatePercentageSum(i) {
      let sum = 0;
      await this.editedItem.blendRecipeDetails.forEach((item, j) => {
        if (j <= i && item.percentage) {
          sum += item.percentage;
        }
      });

      if (sum > 100) {
        this.editedItem.blendRecipeDetails[i].percentage = 0;
        this.showWarningAlert("Combined percentage should be 100");
      }
    },
    async validatePercentage() {
      let sum = 0;

      for await (const item of this.editedItem.blendRecipeDetails) {
        if (item.percentage) {
          sum += item.percentage;
        }
      }

      if (sum != 100) {
        this.showWarningAlert("Combined percentage should be 100");
      }
      return sum;
    },

    async deleteItemConfirm() {
      this.closeDelete();
      try {
        await api.delete(this.editedItem.id);
        this.items.splice(this.editedIndex, 1);
        await this.initialize();
        this.showSuccessAlert(`Recipe Deleted.`);
      } catch (error) {
        this.showErrorAlert(error.message);
      }
    },

    close() {
      this.dialog = false;

      this.editedIndex = -1;

      this.editedItem = Object.assign({}, this.defaultItem);
      this.editedItem.validFrom = new Date(
        Date.now() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .substr(0, 10);
      this.editedItem.validTo = new Date(
        Date.now() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .substr(0, 10);

      this.items = structuredClone(this.tempItems);
    },

    closeDelete() {
      this.dialogDelete = false;
      // this.reset();
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    async save() {
      if (this.validate()) {
        const per_val = await this.validatePercentage();
        if (per_val === 100) {
          try {
            let type = "Saved";
            let item = null;
            if (this.editedIndex > -1) {
              type = "Updated";
              item = await api.edit(this.editedItem.id, this.editedItem);
              Object.assign(this.items[this.editedIndex], this.editedItem);
            } else {
              item = await api.create(this.editedItem);
            }
            if (item.success) {
              await this.initialize();
              this.showSuccessAlert(`Recipe ${type}.`);
            } else {
              this.showErrorAlert(item.message);
            }
          } catch (err) {
            this.showErrorAlert(err.message);
          } finally {
            this.close();
          }
        }
      }
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Recipe   " : "Edit Recipe";
    },
    pages() {
      return this.pagination.rowsPerPage
        ? Math.ceil(this.items.length / this.pagination.rowsPerPage)
        : 0;
    },
  },
};
</script>
